import { BrowserRouter,Route,Routes } from "react-router-dom";
import {ToastContainer} from 'react-toastify'
import { AdminIndex } from "./Components/admin/AdminIndex";
import { ClientList } from "./Components/admin/client/ClientList";
import { Dashboard } from "./Components/admin/dashboard/Dashboard";
import { Myprofile } from "./Components/admin/myprofile/Myprofile";
import { Login } from "./Components/login/Login";
import { MailConfigure } from "./Components/admin/mail/MailConfigure";





function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="/admin" element={<AdminIndex/>} >
              <Route path="dashboard" element={<Dashboard/>}/>
              <Route path="client" element={<ClientList/>}/>
              <Route path="myprofile" element={<Myprofile/>}/>
              <Route path="mail_configuration" element={<MailConfigure/>}/>
              <Route path="change_password" element={<Myprofile/>}/>
            </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>

        
     
  );
}

export default App;
