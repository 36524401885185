import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { isLogedIn } from '../../auth'

import { Footer } from './footer/Footer'
import { Sidebar } from './sidebar/Sidebar'
import { Topbar } from './topbar/Topbar'



export const AdminIndex = () => {


    if (!isLogedIn()) {
        return <Navigate to={"/"} />
    } 

  return (
    <>
    
    <div id="wrapper">

        <Sidebar/>

        <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">
                <Topbar/>


                <Outlet/>


            </div>

            <Footer/>

        </div>

    </div>

   {/* <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
    </a> */}
   </>
  )
}
