import React, { useEffect, useState } from 'react'
import { getCurrentUserDetails ,updateProfile } from '../../../auth';
import { userUpdate } from '../../../Services/User-service';

import {toast} from 'react-toastify'

export const Myprofile = () => {

    const [userProfileDetails,setUserProfileDetails] = useState(
        {
            'name':'',
            'email':'',
        }
    );

    const [User,setUser] = useState(undefined);

    useEffect(()=>{
       
        setUser(getCurrentUserDetails());

        const user  =getCurrentUserDetails();

        setUserProfileDetails({
            ...userProfileDetails,
            name:user.name,
            email:user.email,
        });

    },[userProfileDetails,User]);


    const profileInputChange = (e, field) =>{

        const actualValue = e.target.value;
        setUserProfileDetails({
            ...userProfileDetails,
            [field]:actualValue
        });
    }

    const userProfileSubmit = (event) =>{
        event.preventDefault();

        userProfileDetails['user_id'] = User.id;

        console.log(userProfileDetails);

        userUpdate(userProfileDetails).then(data => {
            console.log(data);
            toast.success(data.message);

            updateProfile(data.data);

        }).catch(
            (error) =>{
                console.log(error);
                toast.error(error);
            }
        )

    }




  return (
    <>

        <div className="container-fluid">

            <h1 className="h3 mb-2 text-gray-800">My Profile</h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">My Profile</h6>
                </div>
                <div className="card-body">
                    <form onSubmit={userProfileSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" id="name" name='name' aria-describedby="emailHelp" placeholder="Enter name" value={userProfileDetails.name} onChange={(e)=> profileInputChange(e,'name')} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input type="email" className="form-control" id="email" name='email' aria-describedby="emailHelp" placeholder="Enter email" value={userProfileDetails.email} onChange={(e)=> profileInputChange(e,'email')} />
                        </div>
                        {/* <div className="form-group col-md-8">
                            <label htmlFor="exampleInputPassword1">Profile Pic</label>
                            <input type="file" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                        </div> */}
                        <div className="form-group col-md-4">
                            
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>

        </div>
    
    </>
  )
}
