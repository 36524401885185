import { myAxios, privateAxios } from "./Helper";

export const userSignUp = (user) => {
    return myAxios.post('register').then((response)=>response.json());
}
export const userLogin = (loginDetails) => {
    return myAxios.post('login',loginDetails).then((response)=>response.data);
}

export const userUpdate = (updateData) => {

    console.log(updateData);

    return privateAxios.post('profile_update',updateData).then((response)=>response.data);
}