import React from 'react'
import { NavLink } from 'react-router-dom'

export const Sidebar = () => {
  return (
    <>
    
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <NavLink className="sidebar-brand d-flex align-items-center justify-content-center" to ="dashboard">
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                </div>
                <div className="sidebar-brand-text mx-3"> Admin <sup>Billing</sup></div>
            </NavLink>

            <hr className="sidebar-divider"/>

            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#admin_dashboard"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Admin</span>
                </NavLink>
                <div id="admin_dashboard" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">All Admin Links:</h6>
                        <NavLink className="collapse-item" to="change_password">Change password</NavLink>
                        <NavLink className="collapse-item" to="mail_configuration">Mail configuration</NavLink>
                        <NavLink className="collapse-item" to="myprofile">Profile</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>


            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Clients</span>
                </NavLink>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">All Clients Links:</h6>
                        <NavLink className="collapse-item" to="client">Client List</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>


            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#product_list_menu"
                    aria-expanded="true" aria-controls="product_list_menu">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Product</span>
                </NavLink>
                <div id="product_list_menu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">All Product Links:</h6>
                        <NavLink className="collapse-item" to="product">Product List</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>


            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Inventory_System_menu"
                    aria-expanded="true" aria-controls="Inventory_System_menu">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Inventory System</span>
                </NavLink>
                <div id="Inventory_System_menu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">All Inventory System:</h6>
                        <NavLink className="collapse-item" to="client">Inventory List</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>


            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Billing_menu"
                    aria-expanded="true" aria-controls="Billing_menu">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Billing</span>
                </NavLink>
                <div id="Billing_menu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">All Billing Links:</h6>
                        <NavLink className="collapse-item" to="client">Billing List</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>


            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Profit_Scenario_menu"
                    aria-expanded="true" aria-controls="Profit_Scenario_menu">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Profit Scenario</span>
                </NavLink>
                <div id="Profit_Scenario_menu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">All Profit Scenario:</h6>
                        <NavLink className="collapse-item" to="client">Profit Scenario</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>


            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Reward_Points_menu"
                    aria-expanded="true" aria-controls="Reward_Points_menu">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Reward & Points - Chart</span>
                </NavLink>
                <div id="Reward_Points_menu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <NavLink className="collapse-item" to="client">Reward & Points List</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>


            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Vendor_Section_menu"
                    aria-expanded="true" aria-controls="Vendor_Section_menu">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Vendor Section</span>
                </NavLink>
                <div id="Vendor_Section_menu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <NavLink className="collapse-item" to="client">Vendor Section List</NavLink>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider"/>

            <li className="nav-item">
                <NavLink className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#Report_menu"
                    aria-expanded="true" aria-controls="Report_menu">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Report</span>
                </NavLink>
                <div id="Report_menu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <NavLink className="collapse-item" to="client">Report List</NavLink>
                    </div>
                </div>
            </li>


            <hr className="sidebar-divider d-none d-md-block"/>

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>

        </ul>
    </>
  )
}
