import React, { useState } from 'react'
import {  useNavigate } from 'react-router-dom';

import {ToastContainer,toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { doLogin } from '../../auth';
import { userLogin } from '../../Services/User-service';

export const Login = () => {

    const [loginDetails,setLoginDetail] = useState({
        email:'',
        password:''
    });

    let navigate = useNavigate();

    const handelChange = (event,field) =>{
        let actualValue = event.target.value;
        setLoginDetail({
            ...loginDetails,
            [field]:actualValue
        });
    }

    const userFormSubmit = (event) =>{
        event.preventDefault();

        console.log(loginDetails);

        if (loginDetails.email === '') {
            console.log('ddd');
                toast.error("User name is required!!");
                return;
        }
        if (loginDetails.password === '') {
                toast.error("User password is required!!");
                return;
        }

        userLogin(loginDetails).then((data)=>{
            console.log(data);
            doLogin(data.data,()=>{
                console.log('Local daa saved');
            });
            toast.success(data.message);
            navigate('admin/dashboard');

            

        }).catch(error=>{
            console.log(error.status);
            toast.error('Something went wrong');
        })


    }

  return (
   <>
   
   <div className="container">

        <div className="row justify-content-center">

            <div className="col-xl-10 col-lg-12 col-md-9">

                <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div className="col-lg-6">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                    </div>

                                    <form className="user" onSubmit={userFormSubmit}>
                                        <div className="form-group">
                                            <input type="email" className="form-control form-control-user"
                                                id="email" name="email" aria-describedby="emailHelp"
                                                placeholder="Enter Email Address..." value={loginDetails.email} onChange={(e)=>handelChange(e,'email')} />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control form-control-user"
                                                id="password" name="password" placeholder="Password"  value={loginDetails.password} onChange={(e) => handelChange(e,'password')} />
                                        </div>
                                        
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox small">
                                                <button className="btn btn-primary btn-user btn-block" >Submit</button>
                                                
                                            </div>
                                        </div>
                                        
                                    </form>

                                    <hr/>
                                    <div className="text-center">
                                        <a className="small" href="forgot-password.html">Forgot Password?</a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        </div>

        <ToastContainer />

   
   </>
  )
}
