import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify';
import { mailDataSave, mailDetails } from '../../../auth';
import { mailServiceSubmit } from '../../../Services/Mail-service';


export const MailConfigure = () => {

    const [my_mail,setMail] = useState(
        {
            'mail_host':'',
            'mail_port':'',
            'mail_username':'',
            'mail_password':'',
            'mail_encryption':'',
            'mail_from_address':'',
            'mail_from_name':'',
            'mail_id':''
        }
        
    );

    useEffect(()=>{
        const mailDetail = mailDetails();

        if (mailDetail !=null) {
            setMail({...my_mail,
                mail_host:mailDetail.mail_host,
                mail_port:mailDetail.mail_port,
                mail_username:mailDetail.mail_username,
                mail_password:mailDetail.mail_password,
                mail_encryption:mailDetail.mail_encryption,
                mail_from_address:mailDetail.mail_from_address,
                mail_from_name:mailDetail.mail_from_name,
                mail_id:mailDetail.id,
            });
        }

        

    },[my_mail])

    const mailHandelChange = (event,field) =>{
        const actualValue = event.target.value
        setMail({...my_mail,[field]:actualValue});

    }

    const mailSubmit = (event) =>{
        event.preventDefault();
        console.log(my_mail);

        mailServiceSubmit(my_mail).then(data=>{
            console.log(data);
            toast.success(data.message);

            mailDataSave(data.data);
            
        }).catch(
            (error) => {
                console.log(error);
                toast.error(error);
            }
        )

    }


  return (
    <>
    
    <div className="container-fluid">

        <div className="card">
                    <div className="card-header">
                        <h4 className="card-title" id="basic-layout-round-controls"><i className="la la-edit"></i>
                        Add/Edit Mail Configuration</h4>
                        <NavLink className="heading-elements-toggle" hhref="#"><i className="la la-ellipsis-v font-medium-3"></i></NavLink>
                        
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <div className="card-text">
                               
                            </div>
                            <form className="form" action="" method="POST" onSubmit={mailSubmit}>
                                
                                    <input type="hidden" name="mailConfigureKey" />
                                    <input type="hidden" name="mailConfigureStatus" />
                                    <div className="form-body">
                                        <div className="form-group">
                                            <div className="form-row">

                                                <div className="col-md-6">
                                                    <label forhtml="mail_host">Mail Host</label>
                                                    <input type="text" id="mail_host"  className="form-control round"
                                                placeholder="Mail Host" name="mail_host" value={my_mail.mail_host} onChange={(e) => mailHandelChange(e,'mail_host')} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label forhtml="mail_port">Mail Port</label>
                                                    <input type="text" id="mail_port"  className="form-control round"
                                                        placeholder="Mail Port" name="mail_port"  value={my_mail.mail_port} onChange={(e) => mailHandelChange(e,'mail_port')} />
                                                </div>

                                                <div className="col-md-6">
                                                    <label forhtml="mail_username">Mail User Name</label>
                                                    <input type="text" id="mail_username"  className="form-control round"
                                                        placeholder="Mail User Name" name="mail_username"  value={my_mail.mail_username} onChange={(e)=> mailHandelChange(e,'mail_username') } />
                                                </div>

                                                <div className="col-md-6">
                                                    <label forhtml="mail_password">Mail Password</label>
                                                    <input type="password" id="mail_password"  className="form-control round"
                                                        placeholder="Mail Password" name="mail_password"  value={my_mail.mail_password} onChange={(e) => mailHandelChange(e,'mail_password')} />
                                                </div>

                                                <div className="col-md-6">
                                                    <label forhtml="mail_encryption">Mail Encription</label>
                                                    <input type="text" id="mail_encryption"  className="form-control round"
                                                        placeholder="Mail Encription" name="mail_encryption"  value={my_mail.mail_encryption} onChange={(e) => mailHandelChange(e,'mail_encryption')} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label forhtml="mail_from_address">Mail From Address</label>
                                                    <input type="text" id="mail_from_address"  className="form-control round"
                                                        placeholder="Mail From Address" name="mail_from_address"  value={my_mail.mail_from_address} onChange={(e) => mailHandelChange(e,'mail_from_address')} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label forhtml="mail_from_name">Mail From Name</label>
                                                    <input type="text" id="mail_from_name"  className="form-control round"
                                                        placeholder="Mail From Name" name="mail_from_name"  value={my_mail.mail_from_name} onChange={(e) => mailHandelChange(e,'mail_from_name')} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions">
                                      
                                        <button type="submit" className="btn btn-primary round">
                                            <i className="la la-check-square-o"></i> Save
                                        </button>
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
    </div>
    
    </>
  )
}
